<template>
    <div class="clientcabin clientcabin-blog">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/citations-google-maps-rankings/1.png"
                        width="100%"
                        max-height="400px"
                        contain
                    />
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        How To Increase Your Google Maps Rankings with
                        {{ company }}’s Citation Building Services
                    </p>
                    <v-card
                        class="blueish black--text px-6 py-5 rounded-lg my-6 elevation-0"
                    >
                        <p class="font-weight-bold">Article at-a-glance</p>
                        <ul>
                            <li>
                                Business mentions across the web can
                                dramatically boost your Google Maps ranking -
                                when done right.
                            </li>
                            <li>
                                The "{{ company }} effect" can transform your
                                local visibility in just days.F
                            </li>
                            <li>
                                Citations can build trust, drive traffic, and
                                establish your brand as a local authority.
                            </li>
                            <li>
                                {{ company }} gets you featured on 300+
                                authority sites, with consistent NAP details and
                                quality content all handled for you.
                            </li>
                        </ul>
                    </v-card>
                    <p>
                        Did you know that
                        <a
                            href="https://mapsplatform.google.com/resources/blog/9-things-know-about-googles-maps-data-beyond-map/"
                            target="_blank"
                        >
                            1 billion people use Google Maps every month?
                        </a>
                        Even if most of those people aren't in your area, you
                        probably understand how valuable ranking high is for a
                        local business.
                    </p>
                    <p>
                        But how can you make sure your business dominates the
                        search results in Google Maps?
                    </p>
                    <p>
                        <b>
                            {{ company }} has designed a Done-For-You content
                            marketing solution
                        </b>
                        that involves minimal effort from your side with the
                        potential of achieving incredible results for your
                        Google Maps rankings. The key to success involves
                        several factors, with rare
                        <b>NAP (Name Address Phone)</b>
                        citations being one of the most impactful ones for your
                        visibility.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/citations-google-maps-rankings/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Google Maps is such a powerful marketing tool that you
                        have to optimize it to be able to grow your local
                        business. {{ company }} knows what works.
                    </p>
                    <p>
                        What we call the {{ company }} effect has a substantial
                        impact on Google Maps rankings through
                        <b>strategic citation building</b>
                        . This effect is usually observed within a matter of
                        days or weeks in less competitive areas or for very
                        specific products, while higher competition keywords and
                        locations (for example, "best burger in New York") may
                        need multiple campaigns and potentially the integration
                        of other marketing strategies.
                    </p>
                    <p>
                        {{ company }} is here to help you figure out the best
                        strategies!
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        What Are Citations & How Do They Influence Your Google
                        Maps Ranking?
                    </p>
                    <p>
                        Citations are online shoutouts that mention a business's
                        NAP details. You can find citations on directories,
                        review sites, social networks, apps, and various other
                        online platforms. By including the NAP information in
                        the content, {{ company }} builds citations on numerous
                        reputable media sites, blog posts, videos, and audio
                        directories.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/citations-google-maps-rankings/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        An example of the NAP details in a news article created
                        and distributed by {{ company }}.
                    </p>
                    <p>
                        Google uses these citations to assess your business's
                        relevance and authority.
                    </p>
                    <p class="text-h5 mt-4">5 Benefits of Citation Building</p>
                    <p>
                        Citation-building has several benefits that go beyond
                        Google Maps rankings:
                    </p>
                    <ol>
                        <li class="mb-2">
                            <b>More visibility in local search results:</b>
                            By building citations, businesses can reach new
                            audiences and attract potential customers who are
                            actively searching for their products and services.
                        </li>
                        <li class="mb-2">
                            <b>Brand recognition and reputation:</b>
                            Citations on reputable directories help businesses
                            establish their brand presence. Customers are more
                            likely to trust and believe companies with
                            consistent and accurate citations.
                        </li>
                        <li class="mb-2">
                            <b>Increased website traffic and leads:</b>
                            Citations improve an organization's online
                            visibility, resulting in more organic traffic to its
                            site from Google. Higher traffic volume leads to
                            more leads and conversions and, ultimately, more
                            revenue.
                        </li>
                        <li class="mb-2">
                            <b>
                                Validation of legitimacy and authority of a
                                business:
                            </b>
                            Citations confirm legitimacy and authority.
                            Businesses that are regularly mentioned across
                            multiple sites and directories are more likely to be
                            viewed as trustworthy and established by search
                            engines and potential customers.
                        </li>
                        <li class="mb-2">
                            <b>Brand and location reinforcement:</b>
                            Citations reinforce brand identity and location.
                            They act as votes of confidence, indicating to
                            search engines that the business is relevant to a
                            specific area. This strengthens the business's
                            position in the local market.
                        </li>
                    </ol>
                    <p class="text-h4 mt-8 text-center">
                        {{ company }} Distribution Builds Online Authority
                    </p>
                    <p>
                        {{ company }} gives small and medium-sized businesses
                        enhanced visibility online and more customers with
                        content produced by a team of highly skilled content
                        writers supported by machine learning and artificial
                        intelligence.
                    </p>
                    <p>
                        {{ company }}'s content distribution service takes
                        businesses on a transformational journey, weaving six
                        forms of content together. The combination of articles,
                        videos, news articles, blog posts, infographics, and
                        audio ads reaches their intended audiences through
                        diverse channels and
                        <u>over 300 sites,</u>
                        many of them authority sites.
                    </p>
                    <p class="text-h5 font-italic">
                        "My small business featured in a news article on a big
                        site like Business Insider?"
                    </p>
                    <p>Yep!</p>
                    <p>
                        Not only will it help improve your online rankings
                        (Google likes news articles online and sees them as
                        perfectly normal,) but build trust with customers who
                        are researching your business or service.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        The Power of Consistency in Google Maps Rankings
                    </p>
                    <p>
                        Understanding Google's perspective helps you work out
                        which strategies work when you're trying to rank higher
                        on Google Maps. Google's primary goal is to determine
                        the closest and most relevant business to a particular
                        location when ranking brick-and-mortar establishments.
                    </p>
                    <p>
                        <u>
                            Consistency in your business information across all
                            citations is the key to success.
                        </u>
                        This means maintaining the same business name, address,
                        and phone number (NAP) across all platforms and content.
                        Inconsistent information can harm your rankings as
                        Google won't be certain if it's looking at the same
                        business or if the address is authentic.
                    </p>

                    <p class="text-h5 mt-4">
                        Best Practices for Effective Citations:
                    </p>

                    <ul>
                        <li class="mb-2">
                            Keep all the key fields consistent in every piece of
                            content you distribute for your business – news
                            announcement, blog post, or ad campaign.
                        </li>
                        <li class="mb-2">
                            Ensure the same business name is used consistently
                            everywhere. Avoid listing variations (e.g., Acme Pty
                            Ltd vs. Acme)
                        </li>
                        <li class="mb-2">
                            Choose one address and maintain consistency across
                            your news announcements and online listings.
                            revenue.
                        </li>
                        <li class="mb-2">
                            While the contact name isn't as important, it's a
                            good idea to keep it consistent as well.
                        </li>
                    </ul>
                    <p>
                        If you run campaigns with {{ company }}, their software
                        <b>
                            automatically incorporates your company's NAP
                            information
                        </b>
                        into all pieces of content, giving you an upper hand in
                        maintaining consistency without lifting a finger!
                        Additionally, {{ company }}'s system keeps track of your
                        brands' citations and brand mentions, maintaining
                        detailed records of each citation-building campaign to
                        track your progress.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/citations-google-maps-rankings/4.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Data from BrightLocal reveals that incorrect information
                        on online directories causes frustration in 93% of
                        consumers, and 80% lose trust.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        How to Dominate the Google Maps Rankings?
                    </p>
                    <p>
                        By leveraging {{ company }}'s citation-building service,
                        you can significantly improve your online visibility,
                        attract a larger customer base, and succeed in your
                        local market. Numerous {{ company }} success stories
                        have been documented, demonstrating remarkable
                        improvements in Google Maps rankings and business
                        growth.
                    </p>
                    <p>
                        If you want to learn more about how their strategies
                        help you with local visibility, check out
                        <router-link to="/info">here.</router-link>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class GoogleMapsRankings extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>
